<template>
  <div class="text">
    <div class="form-group settings-group" v-if="video.length">
      <label class="form-labelx"><b>{{ l.settings.video }}</b></label>
      <label class="form-radio" v-for="(d, index) in video" :key="d.deviceId" v-show="state.isPC || d.deviceId !== 'desktop'">
        <input
          type="radio"
          :id="d.deviceId"
          :value="d.deviceId"
          v-model="state.remoteDeviceVideo"
        />
        <i class="form-icon"></i>
           {{ index }} - {{ d.label }}
      </label>
    </div>
    <!-- <div class="form-group settings-group">
      <video
          class="video"
          ref="video_preview"
          autoplay
          playsinline
          muted
          data-fit="cover"
          autoPictureInPicture="false"
		  webkit-playsinline
		  x5-playsinline
		  x5-video-player-type="h5"
		  x5-video-player-fullscreen="true"
		  custom-cache="false"
      />
    </div> -->
    <div class="form-group settings-group" v-if="audio.length && state.isPC">
      <label class="form-labelx"><b>{{ l.settings.audio }}</b></label>
      <label class="form-radio" v-for="(d, index) in audio" :key="d.deviceId">
        <input
          type="radio"
          :id="d.deviceId"
          :value="d.deviceId"
          v-model="state.remoteDeviceAudio"
        />
        <i class="form-icon"></i>
        {{ index }} - {{ d.label }}
      </label>
    </div>
  </div>
</template>

<style lang="scss">
.video {
    width: 100%;
    max-height: 18rem;
}
</style>

<script>
import { messages } from "../lib/emitter"
import SeaSwitch from "../ui/sea-switch"
import { WebRTC } from "../logic/webrtc"

import { Logger } from "../lib/logger"
const log = Logger("app:app-remote-settings")

export default {
  name: "app-remote-settings",
  components: {
    SeaSwitch,
  },
  data() {
    return {
     
    }
  },
  computed: {
    video() {
      let videoDevices = this.state.remoteDevices.filter(
        (d) => d.kind === "videoinput" && d.deviceId !== "default"
      )
      if (navigator?.mediaDevices?.getDisplayMedia) {
        return [
          {
            deviceId: "desktop",
            label: this.l.settings.desktop + "\xa0 🖥",
          },
          ...videoDevices,
        ]
      }
      return videoDevices
    },
    audio() {
      return this.state.remoteDevices.filter(
        (d) => d.kind === "audioinput" && d.deviceId !== "default"
      )
    },
  },
  methods: {

  },
  watch: {
   
  },
  mounted() {
    

  },
}
</script>
